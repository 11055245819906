import React from "react";
import PillInfo from "./PillInfo/PillInfo";

import viagra from "../../assets/sildenafil-bottle.png";
import cialis from "../../assets/tadalafil-bottle.png";

import "./blog.css";

const Blog = () => {
  return (
    <section id="pricing" className="package-section ptb-100 gray-light-bg">
       <div className="container">
         <div className="row justify-content-center">
           <div className="col-md-8">
             <div className="section-heading text-center mb-5">
               <h2>Our Treatments for ED</h2>
               <p className="lead">
                 We offer prescription medications clinically proven to help improve erections.
               </p>
             </div>
           </div>
         </div>
         <div className="row justify-content-center">
           <div className="col-lg-6 col-md">
             <div className="card" style={{minHeight: "550px"}}>
               <div className="card-header" style={{backgroundColor: '#559ce3', color: '#fff !important'}}><h2 style={{color: '#fff'}}>Viagra (Sildenafil)</h2></div>
               <div className="card-body" style={{backgroundColor:"#559ce3d4",color:"#fff"}}>
                  <img src="https://doko.md/pfizer.webp" />
                 <p className="card-text">Sildenafil is the active ingredient in Viagra. <br /> It is in the group of medicines that doctors call PDE-5 inhibitors and use to treat erectile dysfunction. It’s available from Doko MD in 25mg, 50mg and 100mg strength.</p>
                 <a className="btn solid-btn getitnow" style={{marginTop: '9px',backgroundColor:"#0a4f94",border:"none"}}>Get my Sildenafil <span className="ti-angle-double-right" /></a>
               </div>
             </div>
           </div>
           <div className="col-lg-6 col-md">
             <div className="card" style={{minHeight: "550px"}}>
               <div className="card-header" style={{backgroundColor: '#e8a947', color: '#fff !important'}}><h2 style={{color: '#fff'}}>Cialis (Tadalafil)</h2></div>
               <div className="card-body" style={{backgroundColor:"#e8a947d6",color:"#fff"}}>
                  <img src="https://doko.md/cialis.webp" />
                 <p className="card-text">Tadalafil is the active ingredient in Cialis. <br /> It is approved by the FDA to treat erectile dysfunction. It’s available from Doko MD in 2.5mg , 5mg, 10mg <br /> and 20mg strength.</p>
                 <a className="btn solid-btn getitnow" style={{marginTop: '9px',backgroundColor:"#e98f02",border:"none"}}>Get my Tadalafil <span className="ti-angle-double-right" /></a>
               </div>
             </div>
           </div>
         </div>
         <div className="mt-5 text-center">
         </div>
       </div>
     </section>
  );
};

export default Blog;
