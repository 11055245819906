import "./contactform.css";
import img_5343072 from "../../assets/5343072.png";
import { useState } from 'react';

const ContactForm = () => {


  return (
    <section id="edform" className="contact-us gray-light-bg ptb-100 edform" style={{background: '#3ebeb4', backgroundImage: 'linear-gradient(to left, rgb(167 251 243 / 0%), #00968814, #00968817, #00968866, #009688f0)', padding: '50px 0 50px'}}>
              <div className="container">
                <div className="row justify-content-center">
                <div className="col-md-2">
                </div>
                  <div className="col-lg-8 col-md-8">
                    <div className="section-heading text-left mb-5">

                      <h3 style={{color: '#fff'}}>
                        Prescribed online.
                      </h3>
                      <h3 style={{color: '#fff',fontWeight:"200"}}>
                        Delivered to your door.
                      </h3>
                      <h2 style={{color: '#fff'}}>
                        Ready? Let’s do this!
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-2">
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pb-3 message-box d-none">
                    <div className="alert alert-danger" />
                  </div>
                  <div className="col-md-2">
                  </div>
                  <div className="col-md-8">
                    <div className style={{backgroundColor: '#fff', borderRadius: '5px'}}>
                      <form className="login-signup-form" id="registerform" style={{ textAlign: 'left',
      padding: '15px'}}>
                        <div id="id_wrap_first">
                          <div className="form-group">
                            {/* Label */}
                            <label className="pb-1" style={{color: '#2d2929'}}>
                              First Name
                            </label>
                            {/* Input group */}
                            <div className="input-group input-group-merge">
                              <div className="input-icon">
                                <span className="ti-user color-primary" />
                              </div>
                              <input type="text" name="firstname" id="firstname" className="form-control" placeholder="Enter your first name" style={{backgroundColor: '#eee'}} />
                              <input type="hidden" className="form-control" name="reg_new_user" id="reg_new_user" placeholder="Last Name" />
                            </div>
                          </div>
                          <div className="form-group">
                            {/* Label */}
                            <label className="pb-1" style={{color: '#2d2929'}}>
                              Last Name
                            </label>
                            {/* Input group */}
                            <div className="input-group input-group-merge">
                              <div className="input-icon">
                                <span className="ti-user color-primary" />
                              </div>
                              <input type="text" name="lastname" id="lastname" className="form-control" placeholder="Enter your last name" style={{backgroundColor: '#eee'}} />
                            </div>
                          </div>
                          <div className="form-group">
                            {/* Label */}
                            <label className="pb-1" style={{color: '#2d2929'}}>
                              Email Address
                            </label>
                            {/* Input group */}
                            <div className="input-group input-group-merge">
                              <div className="input-icon">
                                <span className="ti-email color-primary" />
                              </div>
                              <input type="email" name="regusername" id="regusername" className="form-control" placeholder="name@address.com" style={{backgroundColor: '#eee'}} />
                            </div>
                          </div>
                        </div>
                        <div id="id_wrap_other" className>

                          <div className="form-group">
                            {/* Label */}
                            <label className="pb-1" style={{color: '#2d2929'}}>
                              Enter your Phone Number
                            </label>
                            {/* Input group */}
                            <div className="input-group input-group-merge">
                              <div className="input-icon">
                                <span className="ti-mobile color-primary" />
                              </div>
                              <input type="number" className="form-control" name="mobileno" id="mobileno" placeholder="(xxx) xxx - xxxx" style={{backgroundColor: '#eee'}} />
                            </div>
                          </div>
                          <div className="form-group" style={{display: 'none'}}>
                            {/* Label */}
                            <label className="pb-1">
                              States
                            </label>
                            {/* Input group */}
                            <div className="input-group input-group-merge">
                              <div className="input-icon">
                                <span className="ti-map color-primary" />
                              </div>
                              <select className="form-control" name="state" id="state" style={{backgroundColor: '#eee'}}>
                                <option value={1}>Choose States</option>
                                <option value={10} selected>FL</option>
                                <option value={44}>TX</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form-check d-flex align-items-center text-center" style={{textAlign: 'center'}}>
                          <label className="form-check-label small" htmlFor="exampleCheck1">By Signing Up, you agree to our <a href="https://doko.md/privacy" style={{color: '#3ebeb4'}} target="_blank">Privacy Policy</a>, <a href="https://doko.md/terms-of-use" style={{color: '#3ebeb4'}} target="_blank">Terms of Use</a>, <a href="https://doko.md/telemedicine-consent" style={{color: '#3ebeb4'}} target="_blank">Telemedicine Consent</a> and <a href="https://doko.md/refund-policy" style={{color: '#3ebeb4'}} target="_blank">Refund policy.</a></label>
                        </div>


                        <button type="button" id="register_check" className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3">
                          START MY FREE VISIT  <span className="ti-angle-double-right" />
                        </button>

                      </form>
                      <p className="form-message" />
                    </div>
                  </div>
                  <div className="col-md-2">
                  </div>
                </div>
              </div>
            </section>
  );
};

export default ContactForm;
