// import "./shipping.css";
import cardelivery from "../../assets/cardelivery.png";

const Shipping = () => {
  return (
    <section className="testimonial-section " style={{padding: '40px 0px'}}>
            <div className="container">
            <div className="row">
            <div className="col-md-12">
              <div className="section-heading" style={{textAlign: 'center'}}>
                <h3>FREE Shipping </h3>
                <h4>Totally discreet</h4>
              </div>
            </div>
            </div>
              <div className="row justify-content-between align-items-center" style={{}}>
              <div className="col-md-12">
                  <div className="about-content-right">
                    <img src={cardelivery} alt="about us" className="img-fluid" />
                  </div>
                  <center><a href="#edform" className="btn solid-btn page-scroll getitnow"><span className="hero_start_getit"><span>START MY FREE VISIT  </span><i className="fa fa-angle-double-right fa-2x" style={{marginLeft: '5px'}} /></span></a></center>
                </div>
              </div>
            </div>
          </section>
  );
};

export default Shipping;
