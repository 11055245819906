import img_5343072 from "../../assets/5343072.png";
import ata from "../../assets/ata.svg";
import usa from "../../assets/usa.svg";
import "./contactus.css";

const ContactUs = () => {
  return (
    <section id="contact" className="contact-us-section contact-us ptb-100 divsize" style={{backgroundColor: '#1c2c46',textAlign:'left'}}>
              <div className="container">
                <div className="row justify-content-around">
                  <div className="col-lg-4">
                    <div className="contact-us-content">
                      <img src="https://doko.md/img/usa.svg" alt="usa-flag" style={{width: '20px'}} />
                      <h5 style={{color: '#fff'}}>Our Headquarters - USA</h5>
                      <address style={{color: '#FFF'}}>
                        611 Gateway Blvd Suite 120, San Francisco,<br /> CA 94080, United States
                      </address>
                      <span><i className="ti-mobile" style={{color: '#ffffffb8'}} />  <a href="tel:+1-888-910-DOKO(3656)">1-888-910-DOKO(3656) </a></span> <br />
                    </div><br />
                  </div>
                  <div className="col-lg-4">
                    <div className="contact-us-content">
                      <img src="https://doko.md/img/canada.svg" alt="canada-flag" style={{width: '20px'}} />
                      <h5 style={{color: '#fff'}}>Our Headquarters - Canada</h5>
                      <address style={{color: '#FFF'}}>
                        3500 - 2 Bloor St E., <br />
                        Toronto ON M4W 1A8,
                      </address>
                      <span><i className="ti-mobile" style={{color: '#ffffffb8'}} /> <a href="tel:+1-888-910-DOKO(3656)"> 1-888-910-DOKO(3656) </a></span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="contact-us-content">
                      <div className="row justify-content-around" id="dview">
                        <div className="col-lg-6" style={{textAlign: 'center'}}>
                          <img src="https://doko.md/img/ata.svg" alt="ata" style={{}} />
                        </div>
                        <div className="col-lg-6" style={{textAlign: 'center'}}>
                          <a href="https://legitscript.com" target="_blank" title="Verify LegitScript Approval"><img src="https://static.legitscript.com/seals/5343072.png" alt="LegitScript approved" width={140} height={100} border={0}  /></a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div style={{textAlign: 'center', marginTop: '0%'}}>
                      <ul className="list-unstyled social-list mb-0" style={{}}>
                        <li className="list-inline-item"><a href="https://www.facebook.com/in.doko.md" className="rounded"><span className="ti-facebook white-bg color-2 shadow rounded-circle footer-social" /></a></li>
                        <li className="list-inline-item"><a href="https://twitter.com/doko_md" className="rounded"><span className="ti-twitter white-bg color-2 shadow rounded-circle footer-social" /></a></li>
                        <li className="list-inline-item"><a href="https://www.instagram.com/doko.md/" className="rounded"><span className="ti-instagram white-bg color-2 shadow rounded-circle footer-social" /></a></li>
                        <li className="list-inline-item"><a href="https://www.linkedin.com/company/doko-medical-inc/" className="rounded"><span className="ti-linkedin white-bg color-2 shadow rounded-circle footer-social" /></a></li>
                        <li className="list-inline-item"><a href="https://www.pinterest.ca/mktgdokomd/_saved/" className="rounded"><span className="ti-pinterest white-bg color-2 shadow rounded-circle footer-social" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
  );
};

export default ContactUs;
