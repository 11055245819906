import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import Blog from "./components/Blog/Blog";
import Footer from "./components/Footer/Footer";
import Header from "./components/header/Header";
import Hero from "./components/Hero/Hero";
import Heromobile from "./components/Hero/Heromobile";
import DidYouKnow from "./components/DidYouKnow/DidYouKnow";
import Reviews from "./components/Reviews/Reviews";
import Section14 from "./components/Section14/Section14";
import Section15 from "./components/Section15/Section15";
import ContactForm from "./components/ContactForm/ContactForm";
import ContactUs from "./components/ContactUs/ContactUs";
import DidYouKnowSlider from "./components/DidYouKnowSlider/DidYouKnowSlider";
import Shipping from "./components/Shipping/Shipping";
import SectionHowItWorks from "./components/SectionHowItWorks/SectionHowItWorks";
import MiniDescription from "./components/MiniDescription/MiniDescription";
import CertifiedDoctors from "./components/CertifiedDoctors/CertifiedDoctors";
import CerifiedDoctorsCourousal from "./components/CerifiedDoctorsCourousal/CerifiedDoctorsCourousal";
import PharmacyPartner from "./components/PharmacyPartner/PharmacyPartner";
import ComparePrice from "./components/ComparePrice/ComparePrice";
import Comparepricemobile from "./components/ComparePrice/Comparepricemobile";
import ReviewsSlider from "./components/ReviewSlider/ReviewsSlider";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";

import React,{Component} from "react";
import $ from 'jquery';
import { sendToserver,statusNotify,isValidEmail,alertFields,gtag_report_conversion } from './components/JQX/jqx';
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//     gtmId: 'GTM-xxxxxx'
// };

// TagManager.initialize(tagManagerArgs)

class App extends Component {
  // useEffect(() => {
  //   AOS.init({ duration: 2000 });
  // }, []);
componentDidMount(){
  AOS.init();
  // TagManager.initialize({ gtmId: 'AW-653190560' });

  function gtag_report_conversion(url) {

  }
$(document).on("click",".getitnow",function(e){
  e.preventDefault();

$('html, body').animate({
  scrollTop: $("#registerform").offset().top-150
}, 100);
$("#firstname").focus();
});


  $("#register_check").click(function(){
    gtag_report_conversion();
    var mobileno = parseInt($("#mobileno").val());
    var email = $("#regusername").val();
    var lastname = typeof($("#lastname").val())=='undefined'?'':$("#lastname").val();
    var firstname = $("#firstname").val();
    var state = 10;


if (firstname == "" || firstname == null) {
  $("#firstname").css("border-color", "#e61d1d");
  return false;
  }
  if (lastname == "" || lastname == null) {
  $("#lastname").css("border-color", "#e61d1d");
  return false;
  }
  if(!isValidEmail($("#regusername").val())){
      $("#regusername").css("border-color", "#e61d1d");
      statusNotify("Invalid Email ");
       return false;
    }
    if (isNaN(mobileno) || mobileno <= 0 || $("#mobileno").val().length != 10) {
      $("#mobileno").css("border-color", "#e61d1d");
       statusNotify("Invalid Mobile number");
       alertFields("mobileno")
       return false;
   }

    var url = "../crm/fbed/api.php";

    var $form = new FormData();
    $form.append("reg_new_user", "reg_new_user");
    $form.append("firstname", firstname +" "+lastname);
    $form.append("regusername", email);
    $form.append("mobileno", mobileno);
    $form.append("state", state);

    $form.append("lguri", window.location.href);


   try{
   $form.append("uri", window.location.search);
   }catch(ex){
   $form.append("uri", "?req=tr");
   }


    $.when(sendToserver(url, $form)).then(function ($data) {
        var msg = JSON.parse($data);
          var tokixr = msg.token;
        if (msg.status == "success") {
            window.location = '../crm/fbed/api.php?utoken='+tokixr;
        } else {
statusNotify("Welcome back.  Please use Login.", 2);

setTimeout(function(){window.location='../crm/telemed/';},1000);

            // console.log("We ubanle to process att this moment \n please contact help desk");
        }
    });


  })
}


  render(){
  return (
    <div className="App">
      <Header />
      <div className="main">
        <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
        <Hero />
        </div>
        <div className="d-block d-sm-block d-md-block d-lg-none d-xl-none">
          <Heromobile />
        </div>
  <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
        <ComparePrice />
        </div>
<div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
        <Comparepricemobile />
        </div>

        <Blog />

        <div data-aos="fade-up">
          <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
            <CertifiedDoctors />
          </div>
          <div className="d-sm-block d-md-block d-lg-none d-xl-none">
            <CerifiedDoctorsCourousal />
          </div>
        </div>

        <div data-aos="fade-up">
          <PharmacyPartner />
        </div>

        <Shipping />

        <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
          <DidYouKnow />
        </div>
        <div className="d-sm-block d-md-block d-lg-none d-xl-none">
          <DidYouKnowSlider />
        </div>

        <div className="d-block d-sm-block d-md-block d-lg-block d-xl-block">
          <Reviews />
        </div>


        <VideoPlayer />

        <div data-aos="fade-up">
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <Section14 />
          </div>
        </div>
        <div data-aos="fade-up">
          <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <Section15 />
          </div>
        </div>
        <div data-aos="fade-up">
          <ContactForm />
        </div>

        <ContactUs />
      </div>
      <Footer />
    </div>
  );

}
}

export default App;
