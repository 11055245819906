import React from "react";
import "./pharmacypartner.css";

import pharmacyMobile from "../../assets/mobilephramacy.png";

const PharmacyPartner = () => {
  return (
    <section className="imageblock-section switchable switchable-content pharmacybck" style={{backgroundColor:"#3bbbb1"}}>
      <div className=" pt-2 pb-2">
        <div className="text-center" data-aos="fade-up">
        <br />
          <h2 style={{color:"#fff"}}>Our Pharmacy Partner</h2>
          <h4 style={{color:"#fff"}}>
            has <b>30+</b> State Licenses to deliver pills.
          </h4>
        </div>
      </div>
      <div className="row" style={{ marginRight: "0px", marginLeft: "0px" }}>
        <div className="col-lg-12 col-md-12">
          <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block inline_sec9_style1"></div>
          <div className="d-sm-block d-md-block d-lg-none d-xl-none">
            <img src={pharmacyMobile} className="image-mobile" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PharmacyPartner;
