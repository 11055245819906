import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './customtabs.css'
import dokologo from "../../assets/doko-logo.png";
import rexmdlogo from "../../assets/rexmdlogo.svg";
import roman_hu1cyf from "../../assets/roman_hu1cyf.webp";
import upscript_wb4sco from "../../assets/upscript_wb4sco.webp";
import lemonaid_oxu7de from "../../assets/lemonaid_oxu7de.webp";

const ComparePrice = () => {
  return (
    <section id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">

            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <div className="feature-content-wrap">
                <Tabs>
    <TabList>
      <Tab>Sildenafil</Tab>
      <Tab>Tadalafil</Tab>
    </TabList>

    <TabPanel>
    <table style={{marginTop:"5%"}}>
      <tr>
        <th>BRAND</th>
        <th>SILDENAFIL</th>
        <th>PRICE PER TABLETS</th>

      </tr>
      <tr>
        <td style={{backgroundColor:"#3484bb24",color:"#5f6365e6"}}>      <span style={{fontWeight: 700, fontSize: '32px', color: '#009688a8'}}>doko</span></td>
        <td style={{backgroundColor:"#3484bb24",color:"#5f6365e6"}}>100mg</td>
        <td style={{backgroundColor:"#3484bb24",color:"#5f6365e6"}}>$1.00</td>
        <td style={{border:"none",padding:"0px"}}>     <img src="https://www.doko.md/done.png" style={{width:'30px'}} /></td>
      </tr>
      <tr>
        <td > <img src="/app/static/media/rexmdlogo.cf27dbfe524a391c395a61e83f091b64.svg" style={{width: '100px'}} /></td>
      <td>100mg</td>
        <td>$3.00</td>
      </tr>
      <tr>
        <td> <img src="/app/static/media/roman_hu1cyf.21e7c08efc7ad7403677.webp" style={{width: '100px'}} /></td>
      <td>100mg</td>
        <td>$8.00</td>
      </tr>
      <tr>
        <td> <img src="/app/static/media/upscript_wb4sco.354e83e137852b33aa17.webp" style={{width: '100px'}} /></td>
      <td>100mg</td>
        <td>$8.00</td>
      </tr>
    </table>
    </TabPanel>
    <TabPanel>
    <table style={{marginTop:"5%"}}>
      <tr>
        <th>BRAND</th>
        <th>SILDENAFIL</th>
        <th>PRICE PER TABLETS</th>
      </tr>
      <tr>
        <td style={{backgroundColor:"#3484bb24",color:"#5f6365e6"}}>      <span style={{fontWeight: 700, fontSize: '32px', color: '#009688a8'}}>doko</span></td>
        <td style={{backgroundColor:"#3484bb24",color:"#5f6365e6"}}>2.5 or 5mg</td>
        <td style={{backgroundColor:"#3484bb24",color:"#5f6365e6"}}>$1.00</td>
          <td style={{border:"none",padding:"0px"}}>     <img src="https://www.doko.md/done.png" style={{width:'30px'}} /></td>
      </tr>
      <tr>
        <td > <img src="/app/static/media/rexmdlogo.cf27dbfe524a391c395a61e83f091b64.svg" style={{width: '100px'}} /></td>
      <td>2.5 or 5mg</td>
        <td>$3.00</td>
      </tr>
      <tr>
        <td> <img src="/app/static/media/roman_hu1cyf.21e7c08efc7ad7403677.webp" style={{width: '100px'}} /></td>
      <td>2.5 or 5mg</td>
        <td>$8.00</td>
      </tr>
      <tr>
        <td>     <img src="/app/static/media/lemonaid_oxu7de.82197989e4313c1a74cb.webp" alt=""  /></td>
      <td>2.5 or 5mg</td>
        <td>$8.00</td>
      </tr>
    </table>
    </TabPanel>
  </Tabs>
                </div>
              </div>
            </div>
            <div className="col-md-5" style={{textAlign:"left"}}>
            <div className="feature-contents section-heading">
              <h2>Real Meds. <span style={{color: 'rgb(162, 14, 30)',fontWeight:"700"}}>Lowest Prices.</span></h2>
              <p>We guarantee the <span style={{color: 'rgb(162, 14, 30)',fontWeight:"700"}}>lowest price per tablet </span> on generic Viagra®, Cialis®.<span style={{color: 'rgb(162, 14, 30)',fontWeight:"700"}}>Save up to 85% </span> on generic ED medications compared to any telehealth provider.</p>
              <p style={{color: '#353131', fontWeight: 600}}>if you find a lower price we will beat it, guaranteed.</p>
              <div>
              <a className="btn solid-btn getitnow">START MY FREE VISIT <span className="ti-angle-double-right" /></a>
              </div>

              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default ComparePrice;
