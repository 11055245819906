
import "./rewi.css"


const Reviews = () => {
  return (
    <section id="clientslider" className="bg-theme-green ptb-100 pt-2">

      <div className="container">
      <div className="text-center"><br />
        <h3 className="text-white">Check what our Patients are Saying</h3>
      </div><br />
     <div id="demo" className="carousel slide" data-ride="carousel">
       <div className="carousel-inner">
         <div className="carousel-item active">
           <div className="carousel-caption">
             <p>  Online doctor was really knowledgeable, kind and was paying
               attention to my concerns. Sam, the care navigator war really
               punctual in making sure thing go smoothly. </p>
               <div className="client-ratting mt-2"><ul className="list-inline"><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li></ul></div>
               <img src="/app/static/media/rod.a7c166214f8670d6a56b.png" />
             <div id="image-caption">William G, Florida, USA</div>
           </div>
         </div>
         <div className="carousel-item">
           <div className="carousel-caption">
             <p>First time I used telehealth for men’s health. Doko doctor explained
             me all the treatment options available. I am glad I signed up for
             this service.</p>
             <div className="client-ratting mt-2"><ul className="list-inline"><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li></ul></div>
             <img src="/app/static/media/rod.a7c166214f8670d6a56b.png" className="img-fluid" />
             <div id="image-caption">Daniel B, Florida, USA</div>
           </div>
         </div>
         <div className="carousel-item">
           <div className="carousel-caption">
             <p>  Doctor called me back really fast. Its online, but there is still
               human touch. Care navigator was really amazing.</p>
               <div className="client-ratting mt-2"><ul className="list-inline"><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li></ul></div>
                <img src="/app/static/media/rod.a7c166214f8670d6a56b.png" className="img-fluid" />
             <div id="image-caption">Gary F, Florida, USA</div>
           </div>
         </div>
         <div className="carousel-item">
           <div className="carousel-caption">
             <p>As a senior, these are particularly stressful times especially as it
             relates to health and safety. It was reassuring to know I was able
             to get a professional medical diagnosis and treatment that resolved
             my problem. Should I be in need of medical services online I would
             certainly use Doko MD again. Greatly appreciated</p>
             <div className="client-ratting mt-2"><ul className="list-inline"><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li></ul></div>
              <img src="/app/static/media/rod.a7c166214f8670d6a56b.png" className="img-fluid" />
             <div id="image-caption">D. Roderick, NY, USA</div>
           </div>
         </div>
         <div className="carousel-item">
           <div className="carousel-caption">
             <p>  Excellent doc. Great sense of humor also - but gave me good advice
               during a telemedicine appt. Highly recommend</p>
               <div className="client-ratting mt-2"><ul className="list-inline"><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li></ul></div>
               <img src="/app/static/media/rod.a7c166214f8670d6a56b.png" className="img-fluid" />
             <div id="image-caption">Victoria A, NY, USA</div>
           </div>
         </div>
         <div className="carousel-item">
           <div className="carousel-caption">
             <p>  Doko MD service was very fast and helpful. The general physician and
               psychiatrist were very kind and helpful.</p>
              <div className="client-ratting mt-2"><ul className="list-inline"><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li><li className="list-inline-item"><span> <i className="fa fa-star yel" aria-hidden="true" /></span></li></ul></div>
               <img src="/app/static/media/rod.a7c166214f8670d6a56b.png" className="img-fluid" />
             <div id="image-caption">Brent Clart, CA, USA</div>
           </div>
         </div>
       </div> <a className="carousel-control-prev" href="#demo" data-slide="prev">
        <i className="ti-angle-left" style={{backgroundColor:"#1c2c46",padding:"5%"}} />
         </a>
         <a className="carousel-control-next" href="#demo" data-slide="next">
         <i className="ti-angle-right" style={{backgroundColor:"#1c2c46",padding:"5%"}} />
         </a>
     </div>
   </div>

    </section>
  );
};

export default Reviews;
